@import "variables";

.content__audio-video {
  --button-color: $orange-500;
  display: flex;
  flex-direction: column;

  .button {
    align-self: center;
    padding: 8px 20px;
    border-color: var(--button-color);
    color: var(--button-color);

    &:hover {
      background-color: var(--button-color);
      color: $white;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
    }
  }

  video {
    cursor: pointer;
  }
}
