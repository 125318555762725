.icon {
  margin: 10px auto;

  svg {
    display: inline-block;
    width: 55px;
    height: 100%;
  }

  .table & {
    svg {
      width: 36px;
      height: 36px;
    }
  }

  .content-group--row & {
    display: flex;
    margin: 0;
  }
}
