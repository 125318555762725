@import "mixins/breakpoints";

.icon-link {}

.icon-link__link {
  --primary-color: $orange-500;
  --secondary-color: $white;
  --transition-duration: 100ms;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;

  .icon-link__background {
    position: absolute;
    z-index: 1;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color var(--transition-duration) ease;
  }

  &:hover {
    svg {
      fill: var(--secondary-color);
    }

    .icon-link__background {
      outline-color: var(--primary-color);
      background-color: var(--primary-color);
    }
  }

  svg {
    position: relative;
    z-index: 2;
    fill: var(--primary-color);
    width: 20px;
    height: auto;
    transition: fill var(--transition-duration) ease;
  }
}
