@import "variables";

//play pause button
.content-video__video-control-overlay {
  display: inline-flex;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: transparent;
  color: white;
  z-index: 9;
  cursor: pointer;
  padding: 1em;

  svg {
    width: 2em;
    height: 2em;
  }

  .icon--style--white {
    color: white;
  }
  .icon--style--dark {
    color: black;
  }
  .icon--style--orange {
    color: $orange-500;
  }
  .icon--style--moments {
    color: $moments-accent-color;
  }

  .content-video__video-control-overlay__pause {
    display: none;
  }
  .content-video__video-control-overlay__play {
    display: inline-flex;
  }
}
