@import "variables";
@import "mixins/breakpoints";
@import "mixins/typography";

@import "heading";
@import "text";
@import "icon_link";
@import "link";
@import "image";
@import "icon";
@import "video";
@import "audio_video";
@import "spacer";
@import "countdown";
@import "form";

.content-group {}

.content--edit {

  .heading,
  .text,
  .markdown-content,
  .link,
  .icon-link,
  .link-download,
  .icon {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px dashed $gray-400;
      content: " ";
      pointer-events: none;
    }

    .table & {
      &::after {
        display: none;
        position: absolute;
      }
    }
  }
}

.content__classes {
  position: absolute;
  bottom: 1px;
  right: 1px;
  background: $gray-200;
  color: $gray-600;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px dashed gray-300;
  border-left: 0;
  border-top: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
  z-index: 1;

  svg {
    width: 1em;
    height: 1em;
  }

  .table & {
    display: none;
    position: absolute;
    top: -1px;
  }
}
