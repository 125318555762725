@import "mixins/breakpoints";

.link,
.link-download {
  margin: 10px 0;

  @include media-breakpoint-up(sm) {
    display: inline-flex;

    & + & {
      margin-left: 10px;
    }
  }

  .link__button-link {
    @include media-breakpoint-up(sm) {
      min-width: 200px;
    }
  }

  .link__icon-link {
    display: inline-block;
  }

  .icon-position--right {
    &.button {
      svg {
        margin-left: 10px;
        margin-right: 0;
      }

      .link__icon-link {
        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .link-download_desktop {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  .link-download_mobile {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
    .link-download__form-input_hidden {
      display: none;
    }
  }

  .link-download__download-link {
    display: flex;
    justify-content: center;
    flex-flow: column;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .link-download__download {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }
  }

  .link-download__email-link {
    display: flex;
    justify-content: center;
    flex-direction: row;
    border: 2px solid #4e5363;
    border-radius: 4px;
    padding: 2px;
    background-color: $moments-martinique;
  }

  .link-download__hidden {
    display: none;
  }

  .link-download__success-message {
    text-align: left;
    color: white;
  }

  .link-download__email-description {
    padding-top: 10px;
    text-align: left;
    color: white;
  }

  .link-download__download-email {
    padding: 14px 10px;
    background-color: $moments-martinique;
    border: none;
    color: white;
  }

  .link-download__download-email-button {
    padding: 14px 10px;
  }

  .link-download__form {
    display: flex;
  }

  .link-download__form-input {
    align-self: center;
    //background-color: $moments-martinique;
  }

  .link-download__form-action {
    align-self: center;
  }

  .link-download__validation_failed {
    border-color: red;
  }
}
