@import "mixins/breakpoints";

.text {
  display: block;
  width: fit-content;
  margin: 0 auto;

  &.text--p {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &.h--400 {
    font-size: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
  }

  // Max width options
  @include media-breakpoint-up(md) {
    &.maxed-width {
      max-width: 70%;
    }
  }

  &.h--100,
  &.h--200,
  &.h--300,
  &.h--400,
  &.h--500,
  &.h--600 {
    line-height: 1.5;
  }
}
