.spacer {
  border: none;
  margin: 0;

  padding: 5px;
  &.spacer--xlarge {
    padding: 30px;
  }
  &.spacer--large {
    padding: 25px;
  }
  &.spacer--medium {
    padding: 20px;
  }
  &.spacer--small {
    padding: 15px;
  }
  &.spacer--xsmall {
    padding: 10px;
  }
}
