@import "mixins/breakpoints";

.countdown.is-loading {
  opacity: 0;
}

.countdown {
  display: inline-flex;
  gap: 8px;

  @include media-breakpoint-up(sm) {
    gap: 18px;
  }

  @include media-breakpoint-up(md) {
    gap: 32px;
  }
}

.countdown-wrapper {
  display: inline-flex;
  align-items: flex-end;
  line-height: 1;
  gap: 2px;

  @include media-breakpoint-up(sm) {
    gap: 6px;
  }

  @include media-breakpoint-up(md) {
    gap: 16px;
  }

  p {
    position: relative;
  }

  .h--100 {
    margin: 0;
  }
}

.singular {
  display: inline-block;
}

.plural {
  display: none;
}

.is-plural {
  .singular {
    display: none;
  }

  .plural {
    display: inline-block;
  }
}

.countdown-label {
  display: block;
  font-size: clamp(1.4rem, 9.6vw - 3.6rem, 3.6rem);
  font-weight: 700;
  line-height: 1.5;
}

.countdown-number {
  font-size: clamp(3.2rem, 11.4vw - 1.1rem, 9.2rem);
  font-weight: 700;
  line-height: 1;
}
